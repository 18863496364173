@import '../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

@import '../../../styles/ant-theme.less';
@import '../../../styles/typography.less';
@import '../../../styles/utility.less';

@import '../../../styles/ant-overrides.less';
@import '../../../styles/feather-overrides.less';
@import '../../../styles/tailwind/tailwind-output-status.css';

@import '@angular/cdk/overlay-prebuilt.css';
